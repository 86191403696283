import Image from 'next/image';
import styles from '../styles/404.module.css'
import Link from "next/link";
import cn from "classnames";
import {useRouter} from "next/router";
import {en} from "../locales/en";
import {ru} from "../locales/ru";

export default function Custom404() {
    const router = useRouter();
    const t:any = router.locale === 'en' ? en : ru;

    return <div className={styles.pageContainer}>
        <div className={styles.container}>
            <div className={styles.image}>
                <Image width={584} height={240} src={'/404.svg'} alt={'404 page'} />
            </div>

            <h1 className={styles.title}>
                {t.errorPage.title}
            </h1>
            <p className={styles.description}>
                {t.errorPage.text}
            </p>

            <Link href={'/'}>
                <a className={cn('button_base', styles.wAuto)}>
                    {t.errorPage.goBack}
                </a>
            </Link>
        </div>
    </div>
}